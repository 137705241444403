import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { AccordionItemProps } from "design-system/components/blocks/accordion/accordion-item";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { AccordionItemEntry } from "frontend/contentful/schema/primitives";
import { createCtaLinkProps } from "./cta-link.props";

export interface AccordionItemOptions {
  headingLevel?: "h3" | "h4" | "h5";
}

export const createAccordionItemProps = createContentfulProps<
  AccordionItemEntry,
  AccordionItemProps,
  AccordionItemOptions
>(({ entry, options, createEditAttributes }) => {
  return {
    title: <RenderContentfulRichText document={entry.fields.title} />,
    subtitle: <RenderContentfulRichText document={entry.fields.subtitle} />,
    children: (
      <RenderContentfulRichText
        document={entry.fields.description}
        resetHeaders={options?.headingLevel || "h3"}
      />
    ),
    primaryCta: createCtaLinkProps(entry.fields.primaryCta),
    secondaryCta: createCtaLinkProps(entry.fields.secondaryCta),
    disabled: entry.fields.disabled,
    editAttributes: {
      title: createEditAttributes({ entry, fieldId: "title" }),
      subtitle: createEditAttributes({ entry, fieldId: "subtitle" }),
      children: createEditAttributes({ entry, fieldId: "description" }),
    },
  };
});
