import "./accordion.scss";

import cn from "clsx";
import * as RadixAccordion from "@radix-ui/react-accordion";
import {
  ComponentHeader,
  ComponentHeaderProps,
} from "design-system/components/primitives/component-header/component-header";
import { Alignment } from "design-system/types/types";
import { AccordionItem, AccordionItemProps } from "./accordion-item";

export interface AccordionProps {
  /** Optional section heading */
  headerContent?: ComponentHeaderProps;
  /** Global alignment class */
  align?: Alignment;
  HeadingLevel?: {
    titleHeader: "h2" | "h3";
    itemHeader: "h2" | "h3" | "h4";
  };
  items?: Array<AccordionItemProps>;
}

/**
 *
 * The accordion component allows users to hide or reveal content. This is
 * useful for reducing cognitive load for large sections of content.
 *
 * ## How it Works
 * - Accordion contents are hidden by default
 * - Clicking on an accordion item will reveal its contents
 * - Clicking on an accordion item while open when hide its contents
 *
 * ## Accessibility Notes
 * - Adheres to the [Accordion WAI-ARIA design pattern](https://www.w3.org/WAI/ARIA/apg/#accordion).
 *
 * ## Implementation Notes
 * - This component utilizes the [Radix Accordion](https://www.radix-ui.com/docs/primitives/components/accordion) component
 *
 * ## See it in use on...
 * - The [Kitchen Sink page](/story/example-pages-detail-pages--kitchen-sink)
 *
 *
 * - **`id: CB-001-000-00`**
 * - **`data-region: cb__accordion`**
 */
export function Accordion({
  items,
  headerContent,
  align = "center",
  HeadingLevel = {
    titleHeader: "h2",
    itemHeader: "h3",
  },
}: AccordionProps) {
  const wrapperClass = cn(
    `hbs-global-align-${align}`,
    "hbs-component--accordion",
  );

  return (
    <div className={wrapperClass} data-region="cb__accordion">
      <div className={`hbs-accordion`}>
        <div className="hbs-accordion__inner">
          {headerContent && (
            <ComponentHeader
              {...headerContent}
              HeadingLevel={HeadingLevel.titleHeader}
            />
          )}

          {Array.isArray(items) && items.length > 0 && (
            <RadixAccordion.Root type="multiple">
              {items.map((item, i) => (
                <AccordionItem
                  key={i}
                  index={i}
                  {...item}
                  HeadingLevel={HeadingLevel.itemHeader}
                />
              ))}
            </RadixAccordion.Root>
          )}
        </div>
      </div>
    </div>
  );
}
