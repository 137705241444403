import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { AccordionEntry } from "frontend/contentful/schema/blocks";
import {
  Accordion,
  AccordionProps,
} from "design-system/components/blocks/accordion/accordion";
import { createComponentHeaderProps } from "../primitives/component-header.props";
import {
  AccordionItemOptions,
  createAccordionItemProps,
} from "../primitives/accordion-item.props";

interface AccordionOptions {
  headerAbove?: boolean;
}

export const ContentfulAccordion = createContentfulComponent<
  AccordionEntry,
  AccordionOptions
>(({ entry, options }) => {
  const headerAbove = options?.headerAbove || false;

  const props: AccordionProps = {
    headerContent: createComponentHeaderProps(entry.fields.header),
    align: entry.fields.alignment,
    HeadingLevel: {
      titleHeader: headerAbove ? "h3" : "h2",
      itemHeader:
        headerAbove && entry.fields.header
          ? "h4"
          : headerAbove || entry.fields.header
            ? "h3"
            : "h2",
    },
    items: [],
  };

  entry.fields.accordionItems?.forEach((item) => {
    const itemOptions: AccordionItemOptions = {
      headingLevel:
        headerAbove && entry.fields.header
          ? "h5"
          : headerAbove || entry.fields.header
            ? "h4"
            : "h3",
    };
    const itemProps = createAccordionItemProps(item, itemOptions);

    if (itemProps) props.items?.push(itemProps);
  });

  return <Accordion {...props} />;
});
